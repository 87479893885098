import React from "react";
import { Navigate } from "react-router-dom";
import { get } from "../services/cache";

const PrivateRoute = ({ element: Component }) => {
  const userAccessToken = get("fedidLoginAccessToken");
  const supplierAccessToken = get("access_token");
  return (userAccessToken || supplierAccessToken) ? Component : <Navigate to="/" />;
};

export default PrivateRoute;
