import React, { useEffect } from "react";
import { get, set } from "../../services/cache";
import config from "../../services/config";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/apiServices";

export const SupplierLogin = () => {
  const navigate = useNavigate();
  const userAccessToken = get("fedidLoginAccessToken");
  const code = new URL(window.location.href).searchParams.get("code");

  const fetchMemberInfo = async (token) => {
    try {
      const result = await apiServices.getMember(
        `/v1/user/memberpro-info`,
        token
      );
      set({
        key: "claims",
        data: result.data[0],
      });
      set({
        key: "type",
        data: "supplier",
      });
      if (result.data[0].legal_entity_id) {
        navigate("/homepage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      if (code) {
        try {
          const result = await apiServices.post(`/v1/user/login/${code}`);
          if (result.access_token) {
            set({key: "access_token", data: result.access_token})
            fetchMemberInfo(result.access_token);
          } else {
            console.error("Access token not found");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchToken();
  }, [code, navigate]);

  useEffect(() => {
    if (localStorage.getItem("getMemberUrl") === "false") {
      fetch(`${config.baseUrl}/v1/user/login_url/member`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
          "x-api-key": config.apiKey,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          localStorage.setItem("getMemberUrl", true);
          window.location.href = res;
        });
    }
  }, []);

  return (
    <div className=" absolute text-center h-full w-full content-center">
      <div
        className="inline-block h-40 w-40 animate-spin rounded-full  border-[6px] border-solid border-[#3643BA] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};
